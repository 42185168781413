import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { getCurrentYear } from "src/utils/formatDate";

export default function FooterContent() {
  return (
    <Box sx={{ flexGrow: 1 }} my={1} px={4}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Stack direction={"column"}>
          <Typography variant="subtitle2" color="initial">
            {getCurrentYear()} © Inner Circle Prints. All Rights Reserved.{" "}
          </Typography>
          <Typography variant="caption" color="initial">
            The term 'Etsy' is a trademark of Etsy, Inc. This application uses
            the Etsy API but is not endorsed or certified by Etsy, Inc.
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={3} marginRight={8}>
          <NavLink
            to={"/termsofservice"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underLine" }}
          >
            <Typography variant="body2" color="primary">
              Terms of Service
            </Typography>
          </NavLink>

          <NavLink
            to={"/privacypolicy"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underLine" }}
          >
            <Typography variant="body2" color="primary">
              Privacy Policy
            </Typography>
          </NavLink>
        </Stack>
      </Stack>
    </Box>
  );
}
