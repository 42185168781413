import React from "react";
import Iconify from "../iconify/Iconify";
import ImageViewPopup from "./ImageViewPopup";
import { Box, IconButton } from "@mui/material";
import { isStringOrFilePath } from "src/pages/products/details/utils";

const DeleteImageViewer = ({ file, filename, deleteFile }) => {
  return (
    <Box sx={{ position: "relative", width: "80px", height: "80px", my: 2 }}>
      <IconButton
        onClick={deleteFile}
        sx={{
          position: "absolute",
          backgroundColor: "#919EAB",
          zIndex: 2000,
          right: -10,
          top: -10,
          "&:hover": {
            backgroundColor: "#919EAB",
          },
        }}
      >
        <Iconify
          icon="eva:close-fill"
          color="#fff"
          sx={{ width: "16px", height: "16px" }}
        />
      </IconButton>
      <ImageViewPopup
        imageSRC={
          isStringOrFilePath(file) === "String"
            ? [file]
            : [URL.createObjectURL(file)]
        }
        fileName={filename}
      />
    </Box>
  );
};

export default DeleteImageViewer;
