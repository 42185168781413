export const dimensionsArray = [
  {
    id: 5,
    name: "TUMBLER graphics",
    width: 2000,
    height: 1741,
    aspectRatio: "677:589",
  },
  {
    id: 40,
    name: "FROSTED CUP graphics",
    width: 2000,
    height: 920,
    aspectRatio: "1417:653",
  },
  {
    id: 6,
    name: "ORNAMENT graphics",
    width: 1000,
    height: 1000,
    aspectRatio: "1:1",
  },
  {
    id: 27,
    name: "NECKLACE graphics (circle pendant)",
    width: 2000,
    height: 2000,
    aspectRatio: "1:1",
  },
  {
    id: 38,
    name: "BRACELET graphics (circle pendant)",
    width: 2000,
    height: 2000,
    aspectRatio: "1:1",
  },
  {
    id: 39,
    name: "KEYCHAIN graphics (circle pendant)",
    width: 2000,
    height: 2000,
    aspectRatio: "1:1",
  },
  {
    id: 111,
    name: "Notecard",
    width: 1050,
    height: 1050,
    aspectRatio: "1:1",
  },
];

function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

function calculateAspectRatio(width, height) {
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
}

export function checkDimensionsById(id, width, height) {
  const item = dimensionsArray.find((obj) => obj.id === id);
  if (!item) {
    return false;
  }

  const inputAspectRatio = calculateAspectRatio(width, height);
  const itemAspectRatio = calculateAspectRatio(item.width, item.height);

  // Check if the provided dimensions meet or exceed the minimum dimensions
  const meetsMinDimensions = width >= item.width && height >= item.height;

  return (
    meetsMinDimensions &&
    ((width === height && item.width === item.height) ||
      inputAspectRatio === itemAspectRatio)
  );
}

export const getImageDimension = (link) => {
  if (Array.isArray(link)) {
    return link[1]?.metadata ? link[1]?.metadata : "";
  } else {
    return link?.metadata ? link?.metadata : "";
  }
};

export function compareDimensions(uploaded, standard) {
  if (
    uploaded.width === null ||
    uploaded.height === null ||
    standard.width === null ||
    standard.height === null
  ) {
    return null;
  }

  const uploadedAspectRatio = calculateAspectRatio(
    uploaded.width,
    uploaded.height
  );
  const standardAspectRatio = calculateAspectRatio(
    standard.width,
    standard.height
  );

  const uploadedWidth = Number(uploaded.width);
  const uploadedHeight = Number(uploaded.height);
  const standardWidth = Number(standard.width);
  const standardHeight = Number(standard.height);

  if (
    (uploadedWidth === standardWidth && uploadedHeight === standardHeight) ||
    uploadedAspectRatio === standardAspectRatio
  ) {
    return true;
  } else {
    return false;
  }
}
