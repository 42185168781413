import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "src/pages/orders/requests/local/updateOrder";
import { loadingBtnAction } from "src/redux/actions/userActions";
import BirthStones from "./Birthstones";
import Initials from "./Initials";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

const UpdateVariations = ({ item }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const loading = useSelector((state) => state.user.loadingButton);

  const sizeArray =
    item?.catalog?.id === 38
      ? [
          { label: "60 MM (Standard)", value: "60 MM (Standard)" },
          { label: "65 MM (Large)", value: "65 MM (Large)" },
        ]
      : [
          { label: "16“", value: "16“" },
          { label: "18“", value: "18“" },
          { label: "20“", value: "20“" },
          { label: "24“", value: "24“" },
        ];

  const [variations, setVariations] = useState([]);
  const [selectedColor, setSelectedColor] = useState("Gold");
  const [selectedSize, setSelectedSize] = useState(sizeArray[0].value);
  const [personalizationValue, setPersonalizationValue] = useState("");
  const [selectedBirthStones, setBirthStones] = useState([]);
  const [initials, setInitials] = useState([]);

  const initialsArray = item?.personalization_details
    ?.map((item) =>
      item?.formatted_name === "Initials" ? item?.formatted_value : []
    )
    .filter((item) => !isEmpty(item))[0];

  const default_array = //initialize variations
    item?.catalog?.no_of_graphics === 2
      ? [
          {
            property_id: 500,
            value_id: 1,
            formatted_name: "Color",
            formatted_value: "Gold",
          },
          {
            property_id: 506,
            value_id: 2,
            formatted_name: "Size",
            formatted_value: sizeArray[0].value,
          },
          {
            property_id: 54,
            value_id: 3,
            formatted_name: "Personalization",
            formatted_value: "",
          },
          {
            property_id: 504,
            value_id: 4,
            formatted_name: "Birthstones",
            formatted_value: [],
          },
        ]
      : [
          {
            property_id: 54,
            value_id: 3,
            formatted_name: "Personalization",
            formatted_value: "",
          },
        ];

  useEffect(() => {
    //set variations according to personalization_details
    setVariations(
      item?.personalization_details
        ? [
            {
              ...default_array[0],
              ...(item?.personalization_details[0] || {}),
            },
            {
              ...default_array[1],
              ...(item?.personalization_details[1] || {}),
            },
            {
              ...default_array[2],
              ...(item?.personalization_details[2] || {}),
            },
            {
              ...default_array[3],
              ...(item?.personalization_details[3] || {}),
            },
          ]
        : default_array
    );
  }, []);

  useEffect(() => {
    //set default value
    if (variations && variations?.length) {
      let colorObj = variations.filter(
        (item) => item.formatted_name === "Color"
      )[0];
      let sizeObj = variations.filter(
        (item) => item.formatted_name === "Size"
      )[0];
      let personalizationObj = variations.filter(
        (item) => item.formatted_name === "Personalization"
      )[0];
      let birthstonesObj = variations.filter(
        (item) => item.formatted_name === "Birthstones"
      )[0];

      setSelectedColor(colorObj ? colorObj?.formatted_value : "Gold");
      setSelectedSize(sizeObj ? sizeObj?.formatted_value : sizeArray[0].value);
      setPersonalizationValue(
        personalizationObj ? personalizationObj?.formatted_value : ""
      );

      setBirthStones(birthstonesObj ? birthstonesObj?.formatted_value : []);
    } else {
      setSelectedColor("Gold");
      setSelectedSize(sizeArray[0].value);
      setBirthStones([]);
      setPersonalizationValue("");
    }
  }, [variations]);

  const handleSelectedColor = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleSelectedSize = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleUpdate = () => {
    const updatedData =
      item?.catalog?.no_of_graphics === 2
        ? variations?.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                formatted_name: "Color",
                formatted_value: selectedColor,
              };
            } else if (index === 1) {
              return {
                ...item,
                formatted_name: "Size",
                formatted_value: selectedSize,
              };
            } else if (index === 2) {
              return {
                ...item,
                formatted_name: "Personalization",
                formatted_value: personalizationValue,
              };
            } else if (index === 3) {
              return {
                ...item,
                formatted_name: "Birthstones",
                formatted_value: selectedBirthStones,
              };
            }

            return item;
          })
        : [
            {
              formatted_name: "Personalization",
              formatted_value: personalizationValue,
            },
          ];

    let payload = { personalization_details: updatedData };

    dispatch(loadingBtnAction(true));
    updateOrder(
      {},
      item?.id,
      dispatch,
      userType,
      "",
      payload,
      initialsArray,
      initials
    );
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" sx={{ mb: 1.5 }}>
        Update Variations:
      </Typography>
      {item?.catalog?.no_of_graphics === 2 && (
        <>
          <Grid container>
            {item?.catalog?.id === 39 ? (
              ""
            ) : (
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                  Select Size:
                </Typography>
                <FormControl>
                  <RadioGroup
                    defaultValue={
                      variations && variations?.length
                        ? variations[1]?.formatted_value?.split(" ")[0]
                        : sizeArray[0].value
                    }
                    name="radio-buttons-group"
                    value={selectedSize}
                    onChange={handleSelectedSize}
                  >
                    {sizeArray?.map((size) => (
                      <FormControlLabel
                        value={size?.value}
                        control={<Radio />}
                        label={size?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Select Color:
              </Typography>
              <FormControl>
                <RadioGroup
                  defaultValue={
                    variations && variations?.length
                      ? variations[0].formatted_value
                      : "Gold"
                  }
                  name="radio-buttons-group"
                  value={selectedColor}
                  onChange={handleSelectedColor}
                >
                  <FormControlLabel
                    value="Gold"
                    control={<Radio />}
                    label="Gold"
                  />
                  <FormControlLabel
                    value="Silver"
                    control={<Radio />}
                    label="Silver"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <BirthStones
            selectedBirthStones={selectedBirthStones}
            setBirthStones={setBirthStones}
          />
          <Initials initials={initials} setInitials={setInitials} />
        </>
      )}

      <Box sx={{ mt: 1 }}>
        <Typography variant="subtitle1">Personalization:</Typography>
        <TextField
          id="personalization_input"
          label="Personalization"
          multiline
          maxRows={4}
          fullWidth
          sx={{ mt: 2 }}
          value={personalizationValue}
          onChange={(event) => {
            setPersonalizationValue(event.target.value);
          }}
        />
      </Box>
      <Box sx={{ textAlign: "end", mt: 3 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          loadingIndicator={<CircularProgress size={12} color="inherit" />}
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default UpdateVariations;
