import React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { checkURL, getLink, getUploadedImage } from "../order/utils";
import ImageViewPopup from "src/components/image-viewer/ImageViewPopup";

const SKUTable = ({ product }) => {

  console.log(product?.etsy_product_skus, 'product?.etsy_product_skus');
  
  return (
    <TableContainer sx={{ overflowX: "auto" }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              "& th:first-of-type": {
                borderTopLeftRadius: "16px",
              },
              "& th:last-of-type": {
                borderTopRightRadius: "16px",
              },
            }}
          >
            <TableCell align="center">SKU #</TableCell>
            <TableCell align="center">Preview Image</TableCell>
            <TableCell align="center">Graphic</TableCell>
            {product?.catalog?.no_of_graphics === 2 && (
              <TableCell align="center">Notecard Graphic</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {product?.etsy_product_skus?.map((item) => (
            <TableRow
              key={item.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">
                <h6>{item?.sku}</h6>
              </TableCell>
              <TableCell align="center">
                <ImageViewPopup
                  imageSRC={
                    item?.preview_image === null
                      ? product?.catalog?.images
                      : [item?.preview_image]
                  }
                  overlap
                />
              </TableCell>
              <TableCell align="center">
                {checkURL(item?.graphic_image) === "url" ? (
                  <center>
                    <a
                      href={getLink(item?.graphic_image)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button variant="contained" size="small">
                        Edit Graphic
                      </Button>
                    </a>
                  </center>
                ) : (
                  <center>
                    <ImageViewPopup
                      imageSRC={[getUploadedImage(item, "graphic")]}
                      overlap
                    />
                  </center>
                )}
              </TableCell>
              {product?.catalog?.no_of_graphics === 2 && (
                <TableCell align="center">
                  {checkURL(item?.notecard_image) === "url" ? (
                    <center>
                      <a
                        href={getLink(item?.notecard_image)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="contained" size="small">
                          Edit Graphic
                        </Button>
                      </a>
                    </center>
                  ) : (
                    <center>
                      <ImageViewPopup
                        imageSRC={[getUploadedImage(item, "graphic")]}
                        overlap
                      />
                    </center>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SKUTable;
