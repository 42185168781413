import React, { useMemo } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";

import {
  Stack,
  TextField,
  Alert,
  CircularProgress,
  Typography,
  FormControl,
  Button,
  Divider,
  Box,
} from "@mui/material";
import request from "src/utils/request";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { toastify } from "src/utils/toast";
import { CartContainer } from "../Cart.styles";
import { loadingBtnAction, setActiveStep } from "src/redux/actions/userActions";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().min(3).max(32).required(),
  lastName: yup.string().min(3).max(32).required(),
  company: yup.string(),
  phone: yup.string(),

  fullAdress: yup.string(),
  state: yup.string().min(1).max(32).required(),
  street1: yup.string().min(3).max(32).required(),
  street2: yup.string(),
  city: yup.string().min(3).max(32).required(),
  postal_code: yup.string().required(),
});

const ShippingForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [addressFetched, setAddressfetched] = useState(false);
  const loadingBtn = useSelector((state) => state.user.loadingButton);
  const [country, setCountry] = useState({
    value: "US",
    label: "United States",
  });
  const options = useMemo(() => countryList().getData(), []);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    setLoading(true);
    reset();

    const payload = {
      billToName: data.firstName + " " + data.lastName,
      shipToName: data.firstName + " " + data.lastName,
      company: data.company,
      phone: data.phone ? data.phone : "5555555555",
      country: country.value,
      state: data.state,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      postal_code: data.postal_code,
    };
    placeManualOrder(payload);
  };

  const placeManualOrder = async (newOrder) => {
    setLoading(true);
    try {
      const res = await request.post(`/orders`, newOrder);

      if (res) {
        toastify("success", res.data.message);
        navigate("/dashboard/orders");
        setLoading(false);
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      setLoading(false);
    }
  };

  const fillFormFields = async () => {
    const fullAddress = getValues()?.fullAddress;

    dispatch(loadingBtnAction(true));
    try {
      const res = await request.post(`/orders/address/split`, {
        address: fullAddress,
      });

      if (res) {
        reset(res.data.data);
        setCountry(res.data.data.country);
        setAddressfetched(true);
        dispatch(loadingBtnAction(false));
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      dispatch(loadingBtnAction(false));
    }
  };

  return (
    <CartContainer>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Typography variant="h4" gutterBottom>
          Recipient
        </Typography>
        <Stack spacing={3}>
          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("firstName")}
              type="text"
              label="First Name"
              required
            />
            {errors.firstName?.message && (
              <Alert severity="error">{errors.firstName?.message}</Alert>
            )}
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("lastName")}
              type="text"
              label="Last Name"
              required
            />
            {errors.lastName?.message && (
              <Alert severity="error">{errors.lastName?.message}</Alert>
            )}
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("company")}
              type="text"
              label="Company"
            />
            {errors.company?.message && (
              <Alert severity="error">{errors.company?.message}</Alert>
            )}
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("phone")}
              type="number"
              label="Phone"
            />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }}></Stack>
        <Typography variant="h4" gutterBottom>
          Shipping address
        </Typography>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          spacing={4}
          alignItems={"center"}
        >
          <TextField
            {...register("fullAddress")}
            placeholder="Enter full address..."
            type="text"
            required
            fullWidth
          />
          <Box>
            <LoadingButton
              size="small"
              loading={loadingBtn}
              loadingIndicator={<CircularProgress color="inherit" size={12} />}
              onClick={fillFormFields}
              variant="contained"
            >
              Fill
            </LoadingButton>
          </Box>
        </Stack>

        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            OR
          </Typography>
        </Divider>

        {addressFetched && (
          <Alert severity="info">
            Please check address before placing order.
          </Alert>
        )}

        <Stack spacing={3} mt={3}>
          <Stack direction={"row"} justifyContent="space-between">
            <FormControl fullWidth>
              <Select
                options={options}
                defaultValue={country}
                onChange={(e) => setCountry(e)}
                placeholder="Select a country..."
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#212B36"
                      : "rgba(145, 158, 171, 0.32)",
                    height: "55px",
                    borderRadius: "6px",
                    zIndex: 999,
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    maxHeight: "249px",
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </FormControl>
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("state")}
              type="text"
              placeholder="State"
              required
            />
            {errors.state?.message && (
              <Alert severity="error">{errors.state?.message}</Alert>
            )}
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("street1")}
              type="text"
              placeholder="Street 1"
              required
            />
            {errors.street1?.message && (
              <Alert severity="error">{errors.street1?.message}</Alert>
            )}
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("street2")}
              type="text"
              placeholder="Street 2"
            />
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("city")}
              type="text"
              placeholder="City"
              required
            />
            {errors.city?.message && (
              <Alert severity="error">{errors.city?.message}</Alert>
            )}
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("postal_code")}
              type="text"
              placeholder="Postal Code"
              required
            />
            {errors.postal_code?.message && (
              <Alert severity="error">{errors.postal_code?.message}</Alert>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }}></Stack>

        <Stack direction="row" justifyContent={"end"}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => dispatch(setActiveStep(0))}
          >
            Back
          </Button>
          <Stack direction="column" sx={{ mx: 1 }}></Stack>

          <LoadingButton
            type="submit"
            loading={loading}
            loadingIndicator={<CircularProgress size={12} />}
            variant="contained"
          >
            Place Order
          </LoadingButton>
        </Stack>
      </form>
    </CartContainer>
  );
};

export default ShippingForm;
