const defaultState = {
  isAuthenticated: false,
  user: {
    id: 0,
    name: "",
    email: "",
    status: 0,
    image: "",
    type: "",
  },
  isLoading: false,
  loadingButton: false,
  loadingButton2: false,
  skeletonLoading: false,
  token: "",
  userEmail: "",
  activeStep: 0,
  getUsers: false,
  etsyShopName: "",
  userStats: {},
  userMeta: {
    activeUsers: 0,
    inActiveUsers: 0,
    count: 0,
    meta: {},
  },
  userList: [],
  searched: false,

  allUserStats: {},
  singleUserStat: {},
  userPagination: {
    page: 1,
    rowsPerPage: 25,
  },
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "Login":
      return {
        ...state,
        isAuthenticated: true,
        user: {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          status: 1,
          image: action.payload.image,
          type: action.payload.user_type,
        },
        token: action.payload.token,
      };
    case "Logout":
      return {
        ...state,
        isAuthenticated: false,
        user: {
          name: "",
          email: "",
          status: 0,
        },
        token: "",
      };
    case "isAuthorized":
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case "LoadingOn":
      return {
        ...state,
        isLoading: true,
      };
    case "LoadingOff":
      return {
        ...state,
        isLoading: false,
      };

    case "LoadingBtnOn":
      return {
        ...state,
        loadingButton: true,
      };
    case "LoadingBtnOff":
      return {
        ...state,
        loadingButton: false,
      };

    case "LoadingBtn2On":
      return {
        ...state,
        loadingButton2: true,
      };
    case "LoadingBtn2Off":
      return {
        ...state,
        loadingButton2: false,
      };

    case "LoadingSkeletonnOn":
      return {
        ...state,
        skeletonLoading: true,
      };
    case "LoadingSkeletonOff":
      return {
        ...state,
        skeletonLoading: false,
      };

    case "resetPassword":
      return {
        ...state,
        userEmail: action.payload,
      };

    case "activeStep":
      return {
        ...state,
        activeStep: action.payload,
      };

    case "getUsers":
      return {
        ...state,
        getUsers: action.payload,
      };

    case "setEtsyShopName":
      return {
        ...state,
        etsyShopName: action.payload,
      };

    case "setUserStats":
      return {
        ...state,
        userStats: action.payload,
      };

    case "setUserMeta":
      return {
        ...state,
        userMeta: {
          activeUsers: action.payload.activeUsers,
          inActiveUsers: action.payload.inActiveUsers,
          count: action.payload.count,
          meta: action.payload.meta,
        },
      };

    case "setUserList":
      return {
        ...state,
        userList: action.payload,
      };

    case "setSearched":
      return {
        ...state,
        searched: action.payload,
      };

    case "setAllUserStats":
      return {
        ...state,
        allUserStats: action.payload,
      };

    case "setSingleUserStat":
      return {
        ...state,
        singleUserStat: action.payload,
      };

    case "setUserPagination":
      return {
        ...state,
        userPagination: {
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
